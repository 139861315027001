<template>
  <div>
    <div class="navbar">
      <p class="navbar-title">{{ pagename }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    pagename: {
      // 导航栏上页面名称
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  background-color: #ffffff;

  .navbar-title {
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 44px;
    margin: 0;
    text-align: center;
    line-height: 44px;
  }
}
</style>
