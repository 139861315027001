<template>
  <div class="personinfo">
    <BaseNavbar pagename="基本信息填写"></BaseNavbar>
    <div class="personinfo-content">
      <h1>欢迎进入儿童社交能力评估系统</h1>
      <p class="content-info">完善信息，以便获取更精准的评估结果</p>

      <div class="content-item">
        <div class="item-title">
          <div></div>
          <p>请问孩子的昵称是?</p>
        </div>
        <div class="item-nickname">
          <input v-model="childName" type="text" />
        </div>
      </div>

      <div class="content-item">
        <div class="item-title">
          <div></div>
          <p>请问孩子的性别是?</p>
        </div>
        <div class="item-sex">
          <div :class="{ sex: true, 'sex-choice': sex === 'MALE' }" @click="choiceGender('MALE')">
            <img src="@/assets/image/ico_male.png" />
            <p class="sex-name">男生</p>
            <!-- 选项按钮 -->
            <div class="sex-opt">
              <p class="opt"></p>
            </div>
          </div>
          <div :class="{ sex: true, 'sex-choice': sex === 'FEMALE' }" @click="choiceGender('FEMALE')">
            <img src="@/assets/image/ico_female.png" />
            <p class="sex-name">女生</p>
            <!-- 选项按钮 -->
            <div class="sex-opt">
              <p class="opt"></p>
            </div>
          </div>
        </div>
      </div>

      <div class="content-item">
        <div class="item-title">
          <div></div>
          <p>请问孩子的生日是?</p>
        </div>
        <div class="item-birthday">
          <div @click="showPopup('birthday')">
            <input v-model="birthday" readonly />
          </div>
          <van-popup v-model="isshowBrithday" position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              title="选择年月日"
              :min-date="minDate"
              :max-date="maxDate"
              :style="{ height: '50%' }"
              @cancel="cancelBirthday"
              @confirm="confirmBirthday"
            />
          </van-popup>
        </div>
      </div>

      <div class="content-item">
        <div class="item-title">
          <div></div>
          <p>请问您是孩子的?</p>
        </div>
        <div class="item-relation">
          <div
            :class="{ 'current-relation': currentRel === item.zh }"
            v-for="(item, ix) in relationArr"
            :key="ix"
            @click="choiceRelation(item)"
          >
            {{ item.zh }}
          </div>
        </div>
      </div>

      <div class="content-item">
        <div class="item-title">
          <div></div>
          <p>请问您所在的城市是?</p>
        </div>
        <div class="item-city">
          <div @click="showPopup('city')">
            <input v-model="localCity" readonly />
          </div>
          <van-popup v-model="isshowCity" position="bottom">
            <van-picker
              show-toolbar
              title="请选择城市"
              value-key="name"
              :columns="areaList"
              @change="oncityChange"
              @cancel="oncityCancel"
              @confirm="oncityConfirm"
            />
          </van-popup>
        </div>
      </div>
    </div>
    <div class="personinfo-button" @click="sendUser">开始测评</div>
    <p class="personinfo-footer">北大医疗脑健康研发</p>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { sendPersoninfo, areaApi } from '@/api/personinfo'
import BaseNavbar from '@/components/BaseNavbar'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Personinfo',
  components: { BaseNavbar },
  data() {
    return {
      childName: null, // 昵称
      sex: null, // 性别
      isshowBrithday: false,
      minDate: new Date(2000, 0, 1),
      maxDate: null,
      currentDate: new Date(2020, 0, 1),
      birthday: '请选择日期', // 生日
      relationArr: [
        // 与孩子的关系
        { zh: '爸爸', en: 'FATHER' },
        { zh: '妈妈', en: 'MOTHER' },
        { zh: '爷爷', en: 'GRANDFATHER' },
        { zh: '奶奶', en: 'GRANDMOTHER' },
        { zh: '姥姥', en: 'GRANDMA' },
        { zh: '姥爷', en: 'GRANDPA' },
        { zh: '其他', en: 'OTHER' },
      ],
      currentRel: null,
      relationship: null,
      isshowCity: false, // 所在的城市
      areaList: [{ values: [] }, { values: [] }, { values: [] }],
      cityId: null,
      countyId: null,
      provinceId: null,
      valuepro: '',
      valuecit: '',
      valuecou: '',
      localCity: '请选择你所在的城市',
    }
  },
  created() {
    // 获取当前日期并设为最大生日选项，获取省市区信息
    this.getnow()
    this.getArea(142, 0)
  },
  computed: {
    ...mapState(['id']),
    ...mapGetters(['answerID']),
  },
  methods: {
    // 获取日期
    getnow() {
      const now = new Date()
      this.maxDate = now
    },
    // 选择性别和关系
    choiceGender(gend) {
      this.sex = gend
    },
    choiceRelation(rel) {
      this.currentRel = rel.zh
      this.relationship = rel.en
    },
    // 生日、城市弹窗选择
    showPopup(str) {
      switch (str) {
        case 'city':
          this.isshowCity = true
          break
        case 'birthday':
          this.isshowBrithday = true
          break
      }
    },
    // 日期选择取消
    cancelBirthday() {
      this.isshowBrithday = false
    },
    // 确认选择的时间
    confirmBirthday(val) {
      const year = val.getFullYear()
      let month = val.getMonth() + 1
      let day = val.getDate()
      if (month >= 1 && month <= 9) {
        month = `0${month}`
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`
      }
      this.birthday = `${year}-${month}-${day}`
      this.isshowBrithday = false
    },
    // 获取省市区信息
    //index=0获取省，1获取市，2获取区
    getArea(pid, index) {
      areaApi({ parentId: pid }).then(res => {
        // 请求是否成功
        if (res.code === 0) {
          this.areaList[index].values = [...res.data]
          if (index < 2) {
            if (this.areaList[index].values.length === 0) {
              this.getArea(pid, index + 1)
            } else {
              this.getArea(this.areaList[index].values[0].id, index + 1)
            }
          }
        }
      })
    },
    //当地区选择变化时
    oncityChange(picker, values, index) {
      if (index < 2) {
        //依据上层选择的地区的id，获取下一级地址信息
        this.getArea(values[index].id, index + 1)
      }
    },
    //地区取消
    oncityCancel() {
      this.isshowCity = false
    },
    //地区确定
    oncityConfirm(value) {
      this.provinceId = null
      this.valuepro = ''
      this.cityId = null
      this.valuecit = ''
      this.countyId = null
      this.valuecou = ''
      if (value[0]) {
        this.provinceId = value[0].id
        this.valuepro = value[0].name
      }
      if (value[1]) {
        this.cityId = value[1].id
        this.valuecit = value[1].name
      }
      if (value[2]) {
        this.countyId = value[2].id
        this.valuecou = value[2].name
      }
      this.localCity = this.valuepro + this.valuecit + this.valuecou
      this.isshowCity = false
    },

    // 发送用户信息
    sendUser() {
      const { id, cellphone, childName, sex, birthday, relationship, cityId, countyId, provinceId } = this
      const params = { id, cellphone, childName, sex, birthday, relationship, provinceId }
      // 判断是否选择市、区
      if (cityId) {
        params.cityId = cityId
        if (countyId) {
          params.countyId = countyId
        }
      }
      const dateReg = /^[1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
      if (!childName) {
        Toast('请填写孩子的昵称')
      } else if (!sex) {
        Toast('请选择孩子的性别')
      } else if (!dateReg.test(birthday)) {
        Toast('请选择孩子的生日')
      } else if (!relationship) {
        Toast('请选择您与孩子的关系')
      } else if (!provinceId) {
        Toast('请选择你的城市')
      } else {
        sendPersoninfo(params).then(res => {
          if (res.code == 0) {
            this.$router.push({ path: `subject/${this.answerID}` })
          }
        })
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.personinfo {
  margin: 44px 0 0;
  background-color: #f8f8f8;
  overflow: hidden;

  .personinfo-content {
    width: 343px;
    padding-bottom: 26px;
    margin: 16px auto 28px;
    background: #ffffff;
    border-radius: 16px;
    overflow: hidden;

    h1 {
      width: 311px;
      height: 28px;
      margin: 16px auto 0;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1d1d47;
    }

    .content-info {
      width: 311px;
      height: 20px;
      margin: 6px auto;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #aaaaaa;
    }

    .content-item {
      width: 311px;
      margin: 24px auto 0;

      .item-title {
        display: flex;
        align-items: center;
        margin: 0 0 10px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #181944;

        div {
          width: 16px;
          height: 16px;
          margin-right: 8px;
          background: url('~@/assets/image/ico_person_icon.png') center no-repeat;
          background-size: 100%;
        }
        p {
          width: 60%;
          margin: 0;
        }
      }

      .item-nickname {
        padding: 0 0 0 24px;
        border-bottom: 1px solid #d4d4d4;
        input {
          width: 100%;
          height: 30px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #181944;
        }
      }

      .item-sex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 70%;
        margin: 0 auto;

        .sex {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 78px;
            height: 78px;
            border-radius: 50%;
          }
          .sex-name {
            margin: 9px 0;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #181944;
          }
          .sex-opt {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 14px;
            height: 14px;
            border: 3px solid #aaaaaa;
            background-color: #ffffff;
            border-radius: 50%;

            .opt {
              width: 8px;
              height: 8px;
              margin: 0;
              background-color: #ffffff;
              border-radius: 50%;
            }
          }
        }

        .sex-choice {
          .sex-opt {
            border: 3px solid #3350c7;
            .opt {
              margin: 0;
              background-color: #3350c7;
            }
          }
        }
      }

      .item-birthday {
        padding: 0 0 0 24px;
        border-bottom: 1px solid #d4d4d4;
        input {
          width: 100%;
          height: 30px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #181944;
        }
      }

      .item-relation {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        div {
          width: 80px;
          height: 28px;
          margin: 10px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #aaaaaa;
          text-align: center;
          line-height: 28px;
          background: #f7f8f9;
          border-radius: 14px;
        }

        .current-relation {
          color: #ffffff;
          background: linear-gradient(180deg, #5875ee 0%, #3a57ce 100%);
        }
      }

      .item-city {
        padding: 0 0 0 24px;
        border-bottom: 1px solid #d4d4d4;
        input {
          width: 100%;
          height: 30px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #181944;
        }
      }
    }
  }
  .personinfo-button {
    width: 295px;
    height: 40px;
    margin: 28px auto 18px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    background: linear-gradient(180deg, #6288e5 0%, #3350c7 100%);
    border-radius: 20px;
  }

  .personinfo-footer {
    margin: 0 0 38px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #aaaaaa;
    text-align: center;
  }
}
</style>
