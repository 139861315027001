import request from '@/utils/http'

const personApi = {
  sendpersoninfo: 'api/wechat/assessment/user/info',
  area: 'api/wechat/area',
}

// 发送用户信息
export function sendPersoninfo(parameter) {
  return request({
    url: personApi.sendpersoninfo,
    method: 'post',
    data: parameter,
  })
}
// 获取地区
export function areaApi(parameter) {
  return request({
    url: personApi.area,
    method: 'get',
    params: parameter,
  })
}
